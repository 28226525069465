<template>
  <div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      status-icon
    >
      <el-form-item label="中考成绩" prop="grades">
        <el-input v-model="ruleForm.grades" />
      </el-form-item>

      <el-form-item label="姓名" prop="name">
        <el-input v-model="ruleForm.name" />
      </el-form-item>

      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="ruleForm.sex">
          <el-radio label="男" />
          <el-radio label="女" />
        </el-radio-group>
      </el-form-item>

      <el-form-item label="请选择民族" prop="nation">
        <el-select v-model="ruleForm.nation" placeholder="请选择民族">
          <el-option
            v-for="item in nationality"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="身份证号码" prop="id_card">
        <el-input v-model="ruleForm.id_card" disabled />
      </el-form-item>

      <el-form-item label="学历" prop="education">
        <el-input v-model="ruleForm.education" />
      </el-form-item>

      <el-form-item label="住址" prop="addr">
        <el-input v-model="ruleForm.addr" />
      </el-form-item>

      <el-form-item label="手机号" prop="phone">
        <el-input v-model="ruleForm.phone" />
      </el-form-item>

      <el-form-item label="家长姓名" prop="parent_name">
        <el-input v-model="ruleForm.parent_name" />
      </el-form-item>

      <el-form-item label="家长手机号" prop="parent_phone">
        <el-input v-model="ruleForm.parent_phone" />
      </el-form-item>

      <el-form-item label="毕业院校" prop="graduated_school">
        <el-input v-model="ruleForm.graduated_school" />
      </el-form-item>
      <el-form-item label="毕业班级" prop="graduation_class">
        <el-input v-model="ruleForm.graduation_class" />
      </el-form-item>
      <el-form-item label="请选择毕业学校县区 " prop="graduation_address">
        <el-select
          v-model="ruleForm.graduation_address"
          placeholder="请选择毕业学校县区 "
        >
          <el-option
            v-for="item in area"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="毕业班主任" prop="graduating_class_teacher">
        <el-input v-model="ruleForm.graduating_class_teacher" />
      </el-form-item>

      <el-form-item label="院系名称" prop="domain_id">
        <el-select v-model="ruleForm.domain_id" placeholder="请选择院系">
          <el-option
            v-for="item in professionalDropDown"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="所属专业" prop="domain_id_child">
        <el-select
          v-model="ruleForm.domain_id_child"
          placeholder="请选择专业"
          @focus="onTheirProfession(ruleForm.domain_id)"
        >
          <el-option
            v-for="item in theirProfession"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="层次" prop="length">
        <el-select v-model="ruleForm.length" placeholder="请选择层次">
          <el-option
            v-for="item in hierarchicalData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="经办人">
        <el-input v-model="ruleForm.manager" />
      </el-form-item>

      <el-form-item label="经办人部门">
        <el-select v-model="ruleForm.manager_dept" placeholder="请选择经办部门">
          <el-option
            v-for="item in manager"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm(ruleFormRef)"
          >确认</el-button
        >
        <el-button @click="resetForm(ruleFormRef)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { nationality, area, manager } from './slelct'
import {
  listOfSelectionOfHospitalsAPI,
  professionalSelectionListAPI,
  listOfHierarchicalListingAPI
} from '@/api/specialized'
import { registrationDetailsAPI, signUpAndSubmitAPI } from '@/api/reviewed'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
const ruleFormRef = ref(null)
const ruleForm = ref({
  grades: '',
  name: '',
  sex: '',
  nation: '',
  id_card: '',
  education: '',
  addr: '',
  phone: '',
  parent_name: '',
  parent_phone: '',
  graduated_school: '',
  graduation_class: '',
  graduation_address: '',
  graduating_class_teacher: '',
  domain_id: '',
  domain_id_child: '',
  length: '',
  manager: '',
  manager_dept: ''
})

const rules = {
  grades: [
    {
      required: true,
      message: '必须填写中考成绩',
      trigger: 'blur'
    }
  ],
  name: [
    {
      required: true,
      message: '必须填写姓名',
      trigger: 'blur'
    }
  ],
  sex: [
    {
      required: true,
      message: '必须选择性别',
      trigger: 'blur'
    }
  ],
  nation: [
    {
      required: true,
      message: '必须选择民族',
      trigger: 'blur'
    }
  ],
  id_card: [
    {
      required: true,
      message: '必须输入身份证号码',
      trigger: 'blur'
    }
  ],
  education: [
    {
      required: true,
      message: '必须输入学历',
      trigger: 'blur'
    }
  ],
  addr: [
    {
      required: true,
      message: '必须输入住址',
      trigger: 'blur'
    }
  ],
  phone: [
    {
      required: true,
      message: '必须输入手机号',
      trigger: 'blur'
    }
  ],
  parent_name: [
    {
      required: true,
      message: '必须输入家长姓名',
      trigger: 'blur'
    }
  ],
  parent_phone: [
    {
      required: true,
      message: '必须输入家长手机号',
      trigger: 'blur'
    }
  ],
  graduated_school: [
    {
      required: true,
      message: '必须输入毕业院校',
      trigger: 'blur'
    }
  ],
  graduation_class: [
    {
      required: true,
      message: '必须输入毕业班级',
      trigger: 'blur'
    }
  ],
  graduation_address: [
    {
      required: true,
      message: '必须选择毕业学校县区',
      trigger: 'blur'
    }
  ],
  graduating_class_teacher: [
    {
      required: true,
      message: '必须输入毕业班主任',
      trigger: 'blur'
    }
  ],
  domain_id: [
    {
      required: true,
      message: '必须选择毕业院系',
      trigger: 'blur'
    }
  ],
  domain_id_child: [
    {
      required: true,
      message: '必须选择所属专业',
      trigger: 'blur'
    }
  ],
  length: [
    {
      required: true,
      message: '必须选择层次',
      trigger: 'blur'
    }
  ]
}

// 院系
const professionalDropDown = ref([])
const onListOfSelectionOfHospitals = async () => {
  const data = await listOfSelectionOfHospitalsAPI()

  professionalDropDown.value = data
}
onListOfSelectionOfHospitals()

// 所属专业
const theirProfession = ref([])
const onTheirProfession = async (id) => {
  if (id) {
    const data = await professionalSelectionListAPI({
      college_id: id
    })
    theirProfession.value = data
  } else {
    ElMessage.error('请先选择专业')
  }
}

watch(
  () => ruleForm.value.domain_id,
  (val) => {
    onTheirProfession(val)
  },
  {
    deep: true
  }
)

// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}
onLayerPullDown()
// 详情
const route = useRoute()
const onRegistrationDetails = async () => {
  const data = await registrationDetailsAPI({ id: route.params.id })
  ruleForm.value = data
}
onRegistrationDetails()

// 确认
const router = useRouter()
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      await signUpAndSubmitAPI(ruleForm.value)
      ElMessage.success('信息修改成功 ')
      setTimeout(() => {
        router.push('/signUp/audited')
      }, 2000)
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}
</script>

<style></style>
